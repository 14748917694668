export const ArrowRightIcon = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.1201 3.95333L14.1668 8L10.1201 12.0467"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.83325 8H14.0533"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const EmailIcon = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.1666 2.66675H1.83325V13.3334H15.1666V2.66675ZM13.8333 5.33341L8.49992 8.66675L3.16659 5.33341V4.00008L8.49992 7.33341L13.8333 4.00008V5.33341Z"
        fill="white"
      />
    </svg>
  );
};

export const PhoneIcon = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.1579 11.4267L11.4479 8.96266C11.3198 8.84623 11.1515 8.78414 10.9785 8.78948C10.8055 8.79483 10.6413 8.86721 10.5206 8.99133L8.92528 10.632C8.54128 10.5587 7.76928 10.318 6.97461 9.52533C6.17994 8.73 5.93928 7.956 5.86794 7.57466L7.50728 5.97866C7.63155 5.85808 7.70403 5.69388 7.70938 5.5208C7.71474 5.34772 7.65253 5.17936 7.53594 5.05133L5.07261 2.342C4.95597 2.21357 4.79386 2.13567 4.62071 2.12484C4.44756 2.11401 4.27701 2.1711 4.14528 2.284L2.69861 3.52466C2.58335 3.64034 2.51456 3.7943 2.50528 3.95733C2.49528 4.124 2.30461 8.072 5.36594 11.1347C8.03661 13.8047 11.3819 14 12.3033 14C12.4379 14 12.5206 13.996 12.5426 13.9947C12.7056 13.9855 12.8595 13.9164 12.9746 13.8007L14.2146 12.3533C14.328 12.222 14.3855 12.0516 14.3749 11.8785C14.3643 11.7053 14.2864 11.5432 14.1579 11.4267Z"
        fill="white"
      />
    </svg>
  );
};

export const LocationIcon = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.50001 1C7.04184 1.00172 5.6439 1.58174 4.61282 2.61281C3.58174 3.64389 3.00173 5.04184 3.00001 6.5C2.99826 7.69161 3.3875 8.85089 4.10801 9.8C4.10801 9.8 4.25801 9.9975 4.28251 10.026L8.50001 15L12.7195 10.0235C12.7415 9.997 12.892 9.8 12.892 9.8L12.8925 9.7985C13.6127 8.84981 14.0017 7.69107 14 6.5C13.9983 5.04184 13.4183 3.64389 12.3872 2.61281C11.3561 1.58174 9.95817 1.00172 8.50001 1ZM8.50001 8.5C8.10444 8.5 7.71776 8.3827 7.38887 8.16294C7.05997 7.94318 6.80362 7.63082 6.65225 7.26537C6.50087 6.89991 6.46126 6.49778 6.53844 6.10982C6.61561 5.72186 6.80609 5.36549 7.08579 5.08579C7.3655 4.80608 7.72186 4.6156 8.10983 4.53843C8.49779 4.46126 8.89992 4.50087 9.26537 4.65224C9.63082 4.80362 9.94318 5.05996 10.1629 5.38886C10.3827 5.71776 10.5 6.10444 10.5 6.5C10.4993 7.03023 10.2884 7.53855 9.91349 7.91348C9.53856 8.28841 9.03024 8.49934 8.50001 8.5Z"
        fill="white"
      />
    </svg>
  );
};

export const YoutubeIcon = () => {
  return (
    <svg width="41" height="32" viewBox="0 0 41 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40.0399 5.00724C39.5747 3.04925 38.2111 1.5058 36.4818 0.978573C33.3225 0 20.6854 0 20.6854 0C20.6854 0 8.04877 0 4.88955 0.941472C3.19351 1.46814 1.79661 3.04953 1.33139 5.00724C0.5 8.58343 0.5 16 0.5 16C0.5 16 0.5 23.4539 1.33139 26.9928C1.7971 28.9505 3.16024 30.4939 4.88979 31.0211C8.08203 32 20.6859 32 20.6859 32C20.6859 32 33.3225 32 36.4818 31.0585C38.2113 30.5316 39.5747 28.9881 40.0404 27.0304C40.8716 23.4539 40.8716 16.0377 40.8716 16.0377C40.8716 16.0377 40.9048 8.58343 40.0399 5.00724V5.00724ZM16.662 22.8517V9.14832L27.1704 16L16.662 22.8517Z"
        fill="white"
      />
    </svg>
  );
};

export const FacebookIcon = () => {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.564 0H3.17969C1.90503 0 0.871826 1.0332 0.871826 2.30786V29.6921C0.871826 30.9668 1.90503 32 3.17969 32H17.9656V19.625H13.8093V14.7812H17.9656V11.2166C17.9656 7.08276 20.4893 4.83276 24.1768 4.83276C25.9429 4.83276 27.4607 4.96436 27.9031 5.02319V9.34375H25.3604C23.354 9.34375 22.9656 10.2971 22.9656 11.6963V14.7812H27.7625L27.1375 19.625H22.9656V32H30.564C31.8386 32 32.8718 30.9668 32.8718 29.6921V2.30786C32.8718 1.0332 31.8386 0 30.564 0V0Z"
        fill="white"
      />
    </svg>
  );
};

export const TiktokIcon = () => {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.564 0H3.1797C1.90501 0 0.871826 1.03319 0.871826 2.30787V29.6921C0.871826 30.9668 1.90501 32 3.1797 32H30.564C31.8386 32 32.8718 30.9668 32.8718 29.6921V2.30787C32.8718 1.03319 31.8386 0 30.564 0V0ZM24.8873 12.2474V14.4108C23.8671 14.4111 22.8759 14.2113 21.9412 13.8168C21.3401 13.5631 20.7801 13.2361 20.268 12.841L20.2833 19.4999C20.2769 20.9993 19.6837 22.4081 18.6101 23.4692C17.7363 24.333 16.6291 24.8823 15.4291 25.0601C15.1471 25.1018 14.8602 25.1232 14.57 25.1232C13.2855 25.1232 12.0658 24.7071 11.0682 23.9393C10.8805 23.7948 10.701 23.6381 10.53 23.4692C9.36639 22.3193 8.76639 20.7605 8.86739 19.1208C8.94451 17.8726 9.4442 16.6823 10.2773 15.7493C11.3795 14.5146 12.9214 13.8293 14.57 13.8293C14.8602 13.8293 15.1471 13.8511 15.4291 13.8929V14.6927V16.9178C15.1618 16.8296 14.8763 16.7811 14.579 16.7811C13.0727 16.7811 11.8541 18.01 11.8766 19.5173C11.8909 20.4818 12.4178 21.3248 13.1948 21.7895C13.5599 22.0079 13.98 22.1432 14.4283 22.1678C14.7796 22.1871 15.1168 22.1382 15.4291 22.0351C16.5053 21.6796 17.2816 20.6688 17.2816 19.4766L17.2851 15.0175V6.87675H20.2644C20.2673 7.17194 20.2972 7.46 20.3529 7.73906C20.5778 8.86875 21.2145 9.84888 22.1008 10.5171C22.8735 11.0999 23.8358 11.4454 24.8788 11.4454C24.8794 11.4454 24.888 11.4454 24.8873 11.4447V12.2474H24.8873Z"
        fill="white"
      />
    </svg>
  );
};

export const InstagramIcon = () => {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.6843 16C19.6843 17.5532 18.425 18.8125 16.8718 18.8125C15.3186 18.8125 14.0593 17.5532 14.0593 16C14.0593 14.4468 15.3186 13.1875 16.8718 13.1875C18.425 13.1875 19.6843 14.4468 19.6843 16Z"
        fill="white"
      />
      <path
        d="M21.6218 7.5H12.1218C10.054 7.5 8.37183 9.18213 8.37183 11.25V20.75C8.37183 22.8179 10.054 24.5 12.1218 24.5H21.6218C23.6897 24.5 25.3718 22.8179 25.3718 20.75V11.25C25.3718 9.18213 23.6897 7.5 21.6218 7.5ZM16.8718 20.6875C14.2871 20.6875 12.1843 18.5847 12.1843 16C12.1843 13.4153 14.2871 11.3125 16.8718 11.3125C19.4565 11.3125 21.5593 13.4153 21.5593 16C21.5593 18.5847 19.4565 20.6875 16.8718 20.6875ZM22.2468 11.5625C21.729 11.5625 21.3093 11.1428 21.3093 10.625C21.3093 10.1072 21.729 9.6875 22.2468 9.6875C22.7646 9.6875 23.1843 10.1072 23.1843 10.625C23.1843 11.1428 22.7646 11.5625 22.2468 11.5625Z"
        fill="white"
      />
      <path
        d="M24.4343 0H9.30933C4.65698 0 0.871826 3.78516 0.871826 8.4375V23.5625C0.871826 28.2148 4.65698 32 9.30933 32H24.4343C29.0867 32 32.8718 28.2148 32.8718 23.5625V8.4375C32.8718 3.78516 29.0867 0 24.4343 0ZM27.2468 20.75C27.2468 23.8516 24.7234 26.375 21.6218 26.375H12.1218C9.02026 26.375 6.49683 23.8516 6.49683 20.75V11.25C6.49683 8.14844 9.02026 5.625 12.1218 5.625H21.6218C24.7234 5.625 27.2468 8.14844 27.2468 11.25V20.75Z"
        fill="white"
      />
    </svg>
  );
};

export const EmailBoxIcon = () => {
  return (
    <svg width="40" height="28" viewBox="0 0 40 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.0334 25.8334C38.2422 26.7834 37.0699 27.333 35.8335 27.3334H4.16679C2.93039 27.333 1.75805 26.7834 0.966797 25.8334L16.2501 13.1001L17.8335 14.1835C19.1378 15.0835 20.8625 15.0835 22.1668 14.1835L23.7502 13.1001L39.0334 25.8334Z"
        fill="#215DD1"
      />
      <path
        d="M39.2666 2.48352L23.75 13.1001L22.1666 14.1835C20.8623 15.0835 19.1376 15.0835 17.8333 14.1835L16.2499 13.1001L0.733398 2.4668C1.51418 1.34094 2.7966 0.668602 4.16675 0.666806H35.8333C37.2097 0.65954 38.4984 1.34141 39.2666 2.48352Z"
        fill="#64B5F6"
      />
      <path
        d="M16.25 13.1001L0.966713 25.8334C0.341167 25.0857 -0.00109781 24.1416 7.40628e-05 23.1668V4.83343C-0.00500405 3.98789 0.251167 3.16133 0.733432 2.4668L16.25 13.1001Z"
        fill="#3E79ED"
      />
      <path
        d="M40 4.83339V23.1667C40.0011 24.1416 39.6589 25.0857 39.0333 25.8334L23.75 13.1L39.2666 2.4834C39.7465 3.17293 40.0025 3.99332 40 4.83339Z"
        fill="#3E79ED"
      />
    </svg>
  );
};
